.m-control-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $dark-grayer;
  padding: 1rem 0;
  z-index: 1001;

  .m-control-panel-header {
    text-align: center;
    color: #fff;
    margin-bottom: 1rem;
  }

  .m-control-panel-inner {
    display: flex;
    justify-content: center;
  }

  .m-control-panel-tag {
    display: inline-block;
    font-size: 12px;
    border: 1px solid #fff;
    border-radius: 15px;
    padding: 2px 5px;
    margin: 0 0.5rem 0.5rem;
  }

  .m-control-panel-btn {
    padding: 0.5rem 1rem;
    text-align: center;
    margin: 0 0.5rem;

    @media (min-width: 350px) {
      min-width: 7.5rem;
    }

    i {
      margin-right: 0.5rem;
    }
  }
}

.m-control-screen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $dark-grayer;
  z-index: 1102;
  transform: translateY(200%);
  transition: all 0.5s ease-in-out;

  &.is-open {
    transform: translateY(0%);
  }

  .m-control-screen-header {
    color: #fff;
    padding: 1rem 3rem;
    font-size: 1.8rem;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;

    i {
      float: right;
      font-size: 2rem;
      margin: 0.2rem;
    }
  }

  .m-control-screen-body {
    flex: 1;
    overflow-y: auto;
    padding: 2rem 3rem 7rem 3rem;
  }

  .form-group {
    margin-bottom: 1rem;

    .control-label {
      color: #fff;
    }
  }

  .m-control-screen-button,
  .form-buttons {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1.5rem 0;
    text-align: center;
    background: $dark-grayer;

    .btn-clear-filters {
      display: none;
    }

    button[type="submit"] {
      float: none !important;
    }
  }
}

.activity-filter {
  margin-bottom: 2rem;

  .activity-filter-header {
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .activity-filter-items {
    display: flex;
    justify-content: center;
  }

  .form-group {
    .control-label {
      display: none;
    }
  }

  .activity-filter-item {
    text-align: center;
    align-self: flex-end;

    label {
      padding: 0.5rem 1rem 0.5rem;
      color: #fff;
      font-weight: normal;
      border-bottom: 3px solid #fff;

      &.active {
        color: $vivid-orange;
        border-color: $vivid-orange;
      }
    }

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      margin: 0;
      z-index: -1;
      width: 0;
      height: 0;
      overflow: hidden;
      left: 0;
      pointer-events: none;
    }
  }
}
