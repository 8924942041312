header {
  height: 60px;
  background-color: lighten($slate, 5%);
  color: #fff;
  .row {
    margin: 0;
    width: 100%;
    @include respond-to(screen) {
      // Resets to vendor defaults
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .user-management {
    float: right;
    font-family: $default-font-family;
    text-transform: uppercase;
    min-width: 150px;
    background-color: $slate;
    .name {
      color: #fff;
      line-height: 60px;
      padding: 0 10px 0 15px;
      text-align: center;
    }
    .menu-dropdown-drawer {
      li {
        padding: 0;
        a {
          display: block;
          width: 100%;
          height: 100%;
          padding: 10px 15px;
          color: #fff;
          transition: all 0.1s ease-in-out;
          &:hover {
            text-decoration: none;
          }
          .fa {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1001;
    max-width: 100vw;
    .menu-toggle {
      position: relative;
      float: right;
      width: 50px;
      height: 50px;
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 3rem;
        font-family: "Font Awesome 5 Pro";
        font-weight: 600;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f0c9";
        font-size: 30px;
        margin-left: -15px;
        margin-top: -20px;
      }
    }
  }
}

.app-header {
  $header-height: 60px;
  // TODO: The header's height is currently fixed. We may eventually want to
  // change that (maybe use flex or something), at which point we may want to
  // remove this little bit of extra padding on the top.
  background: #fff;
  height: $header-height;
  flex: 0;

  &__container {
    position: relative;
    height: $header-height;

    &--whitelabeled {
      padding-left: 112px; // This is to accomodate the logo
      justify-content: flex-end;
    }

    @include on-mobile {
      padding: 0 8px;
    }
  }

  &__nav {
    @include on-mobile {
      display: none;
    }
  }

  &__nav-link {
    display: inline-block;
    margin: 4px;
    padding: 8px 16px;
    border-bottom: 2px solid transparent;
    color: #000;
    font-size: 18px;
    text-decoration: none;
    transition: border-color 150ms ease-out;
    cursor: pointer;

    &:hover,
    &:focus,
    &--is-active {
      color: #000;
      border-color: $color-secondary;
      text-decoration: none;
    }
  }

  &__logo {
    display: inline-block;
    height: 40px;
    flex: 0 0 auto;

    &__container {
      margin-right: 16px;

      &--whitelabeled {
        position: absolute;
        left: 0;
        height: 90px;
        width: 90px;
        top: 8px;
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.3);
        overflow: hidden;

        > img {
          height: initial;
          width: 100%;
        }

        @include on-mobile {
          top: 2px;
          left: 50%;
          transform: translateX(-50%);
          width: 54px;
          height: 54px;
        }
      }
    }
  }

  &__user-menu {
    @include on-mobile {
      display: none;
    }
  }

  &__icon {
    color: #000;
    font-size: 28px;
  }

  &__mobile-menu-button {
    display: none;

    @include on-mobile {
      display: block;
    }
  }
}

.app-sport-switcher {
  margin-right: 16px;
  cursor: pointer;
  color: black;

  &__toggle-icon {
    margin-left: 5px;
    margin-right: 10px;
    color: black;
  }

  &__toggle-text {
    text-align: right;
    color: black;
  }

  &__toggle-text-sport {
    font-weight: bold;
    white-space: nowrap;
  }

  &__item {
    &__active-icon {
      color: $color-secondary;
      margin-left: 8px;
    }
  }
}
