.menu-tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 2rem;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #ccc;

  li.tab {
    display: block;
    font-weight: 700;
    transition: all 0.1s ease-in;
    border-bottom: 3px solid transparent;
    text-transform: uppercase;

    &.active {
      border-bottom: 3px solid $color-secondary;
    }

    &:not(:last-of-type) {
      margin-right: 5px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      border-bottom: 3px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    a {
      display: block;
      padding: 10px 15px;
      width: 100%;
      height: 100%;
      color: #000;
      text-decoration: none;
    }
  }
}

.menu-pills {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-top: 1px;
  margin-bottom: 3rem;
  padding: 0;
  list-style: none;
  background-color: #eee;
  width: 100%;
  overflow: hidden;

  li.pill {
    float: left;
    text-transform: capitalize;
    transition: all 0.1s ease-in;

    &.active {
      background-color: #ccc;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    a {
      display: block;
      padding: 5px 20px;
      width: 100%;
      height: 100%;
      color: #000;
      text-decoration: none;
    }
  }
}

.menu-select {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #ccc;

  &:after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    content: "\f107";
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -13px;
    transition: all 0.15s ease-in-out;
  }

  &.active {
    &:after {
      transform: rotate(180deg);
    }

    .menu-options {
      visibility: visible;
      opacity: 1;
    }
  }

  .current-option {
    width: 100%;
    height: 100%;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default;
  }

  .menu-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 300px;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
    background-color: #fff;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    transition: all 0.15s ease-in-out;

    li {
      position: relative;
      padding: 10px;
      padding-left: 35px;
      border-bottom: 1px solid #eee;

      &:hover {
        background-color: rgba(0, 0, 0, 0.15);
        cursor: pointer;
      }

      &:before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 600;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f00c";
        opacity: 0.1;
        position: absolute;
        left: 10px;
        top: 50%;
        margin-top: -7px;
      }

      &.selected {
        font-weight: 700;

        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.menu-dropdown {
  position: relative;
  height: 60px;
  display: inline-block;
  user-select: none;
  padding-right: 25px;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: lighten($slate, 10%);
    cursor: pointer;
  }

  &:after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f0d7";
    position: absolute;
    right: 15px;
    top: 23px;
    transition: all 0.2s ease-in-out;
  }

  &.active {
    &:after {
      transform: rotate(180deg);
    }

    .menu-dropdown-drawer {
      visibility: visible;
      opacity: 1;
    }
  }

  .selected-item {
    line-height: 60px;
    padding: 0 15px;
    color: #8f8f88;

    .item-label {
      font-family: $default-font-family;
      text-transform: uppercase;
      color: #fff;
      margin-left: 5px;
    }
  }

  .menu-dropdown-drawer {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    background-color: $slate;
    width: 100%;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transition: all 0.15s ease-in-out;

    li {
      padding: 10px 15px;
      color: #fff;
      transition: all 0.1s ease-in-out;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        color: #fff;
        cursor: pointer;
      }

      .fa {
        margin-left: 5px;
      }
    }
  }
}

@media (max-width: 991px) {
  .menu-tabs {
    li.tab {
      margin: 0;
      text-align: center;

      &:not(:last-of-type) {
        margin: 0;
      }

      &.active {
        background-color: rgba(0, 0, 0, 0.1);
        //border-bottom: 3px solid rgba(0, 0, 0, .1);
      }
    }
  }

  .menu-pills {
    li.pill {
      width: 50%;
      padding: 10px 20px;
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
      text-align: center;
    }
  }
}
