.administration {
  table {
    tr {
      td {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:nth-child(1) {
          width: 250px;
          max-width: 250px;
        }

        &:nth-child(2) {
          width: 250px;
          max-width: 250px;
        }
      }
    }
  }
}

// Logo Upload

.uploadOverlay {
  background-color: darken($dorian-gray, 12%);
  margin-top: 1em;
  width: 200px;
}

#upload-logo {
  display: block;

  &:hover {
    text-decoration: none;
    transition: $transition-default;
    background: $dorian-gray;
  }

  &:focus {
    text-decoration: none;
  }

  img {
    padding: 1em;
    width: 100%;
  }

  .change {
    font-size: 1em;
    color: #fff;
    text-align: center;
    padding-bottom: 1em;
  }
}

.athlete-profile {
  .modal {
    &.active {
      z-index: 999999;
    }
  }

  .cards .card .card-content .card-table {
    @include respond-to(tablet) {
      max-width: 20%;
    }
  }
}

.admin-section-athletes {
  .btn-group {
    @media (max-width: 767px) {
      display: block;
      margin-bottom: 1.5em;
    }
  }

  .table--sortable-header {
    th {
      &:nth-child(2),
      &:nth-child(3) {
        @media (min-width: 767px) {
          padding-left: 12rem;
        }
      }

      &:nth-child(2) {
        display: none;

        @media (min-width: 767px) {
          display: block;
        }
      }
    }
  }

  .table--administration {
    @media (min-width: 767px) {
      tr {
        td {
          &:nth-child(1) {
            width: 25%;
          }
          &:nth-child(2) {
            width: 5%;
          }
          &:nth-child(3) {
            width: 30%;
          }
          &:nth-child(4) {
            width: 20%;
          }
          &:nth-child(5) {
            width: 15%;
          }
          &:nth-child(6) {
            width: 5%;
          }
        }
      }
    }
  }
}

.admin-section-teams {
  .section-actions {
    .section-actions-field {
      margin-bottom: 0.5rem;

      label {
        margin-right: 1rem;
      }

      select {
        @media (min-width: 992px) {
          width: 70%;
          display: inline-block;
        }
        @media (min-width: 1199px) {
          width: 75%;
        }
      }
    }

    .section-actions-buttons {
      padding: 0.8rem 0;
      text-align: right;
    }
  }
}
