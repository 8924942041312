.banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  &.bleachers {
    background-image: url(#{$assetPath}/backgrounds/bleachers.jpg);
  }

  &.lacrosse-players-men {
    background-image: url(#{$assetPath}/backgrounds/lacrosse-players-men.jpg);
  }

  &.lacrosse-players-women {
    background-image: url(#{$assetPath}/backgrounds/lacrosse-players-women.jpg);
  }

  &.soccer-ball-goal {
    background-image: url(#{$assetPath}/backgrounds/soccer-ball-goal.jpg);
  }

  &.soccer-ball-players {
    background-image: url(#{$assetPath}/backgrounds/soccer-ball-players.jpg);
  }

  &.soccer-ball {
    background-image: url(#{$assetPath}/backgrounds/soccer-ball.jpg);
  }

  &.soccer-field {
    background-image: url(#{$assetPath}/backgrounds/soccer-field.jpg);
  }

  &.diamond {
    background-image: url(#{$assetPath}/backgrounds/diamond.png);
  }
}

.signin {
  &.modal {
    display: flex;
    align-items: center;
    visibility: visible;
    opacity: 1;

    .modal-dialog {
      .modal-header {
        background-color: #f0efee;

        .logo {
          display: inline-block;
          border-right: 1px solid #006fba;
          margin-right: 15px;

          img {
            height: 50px;
            margin-right: 20px;
          }
        }

        h3 {
          display: inline-block;
          margin-top: 15px;
          color: #006fba;
        }

        &.se-login {
          .logo {
            border-right: none;

            img {
              height: auto;
              max-width: 100%;
              max-height: 62px;
            }
          }
        }
      }
    }
  }
}

.credit-card {
  &.modal {
    @media (min-width: 1199px) {
      display: flex;
      align-items: center;
      visibility: visible;
      opacity: 1;
    }

    .modal-dialog {
      .modal-header {
        .logo {
          display: inline-block;
          border-right: 1px solid #fff;
          margin-right: 15px;

          img {
            height: 50px;
            margin-right: 20px;
          }
        }

        .partner-logo {
          display: none;

          @media (min-width: 460px) {
            display: block;
            float: right;
          }

          img {
            height: 50px;
            width: 100px;
            margin-left: 20px;
          }
        }

        h3 {
          display: inline-block;
          margin-top: 15px;
        }
      }
    }
  }
}
