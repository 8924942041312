.pagination {
  &.pagination-left {
    float: left;
  }

  &.pagination-right {
    float: right;
  }

  &.pagination-center {
    margin: auto;
    width: 180px;
  }

  &.pagination--inlined {
    display: inline-block;
  }

  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 3rem;
  font-size: 2rem;

  li {
    float: left;
    text-align: center;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-right: 5px;
    }

    &:hover {
      background-color: #eee;
    }

    &.active {
      border-bottom: 3px solid $color-secondary;
    }

    a {
      display: block;
      padding: 5px 10px;
      width: 100%;
      height: 100%;
      color: $color-primary;
      text-decoration: none;
      outline: none;
    }

    .fa {
      font-size: 2.25rem;
    }
  }
}
