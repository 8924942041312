.dashboard-page {
  @include on-mobile {
    flex-direction: column;
  }

  &__separator {
    width: 100%;
    height: 2px;
    background-color: #d8d8d8;
    border: none;
  }

  &__main {
    max-width: calc(70% - 10px);

    &__content {
      width: 100%;
    }
  }

  &__right-sidebar {
    max-width: calc(30% - 10px);
  }
}

.dashboard-widget {
  &__title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  &__subtitle {
    color: $dorian-gray;
    font-size: 14px;

    @include on-mobile {
      display: none;
    }
  }

  &__body {
    padding-top: 10px;
  }
}

.welcome-to-ted-widget {
  &__youtube-thumbnail {
    display: block;
    position: relative;
    margin: 10px 0;

    &__image {
      max-width: 100%;
    }

    &__play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: white;
      font-size: 48px;
      z-index: 1;
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &__youtube-tag {
    font-weight: bold;

    &__youtube-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      color: white;
      font-size: 18px;
      background-color: #3ab0dd;
      border-radius: 24px;
      margin-right: 4px;
    }
  }

  &__collapse-toggle {
    cursor: pointer;
    padding: 20px;
    margin: -20px;
  }
}

.pro-tip-widget {
  &__send-roster-btn {
    margin: 16px 0px;
  }
  &__ncsa-logo {
    max-height: 38px;
    margin-top: 5px;
    margin-right: 8px;
  }
  &__title {
    font-style: italic;
  }
}

.most-recent-athlete-logins-widget {
  @include on-mobile {
    overflow: hidden;
  }

  &__athlete-cards {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__no-athletes {
    padding: 80px 0;

    &__message {
      font-style: italic;
      margin-bottom: 20px;
    }
  }
}

.athlete-login-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  margin: 5px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 275px;
  flex: 0 0 180px;

  &--is-pending {
    opacity: 0.5;
  }

  &__name {
    margin: 5px 0;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  &__last-login-container {
    margin-top: 10px;
  }

  &__last-login-label {
    font-size: 14px;
    color: #9b9993;
    font-style: italic;
  }

  &__last-login-value {
    color: #333;
    font-size: 20px;
    font-weight: bold;
  }

  &__roster-action-needed {
    text-align: center;
  }

  &__more-actions-menu {
    &__toggle-button {
      padding: 10px;
      margin: -10px;
    }
  }
}

.active-collges-widget {
  margin-bottom: 20px;

  @include on-mobile {
    overflow: hidden;
  }

  &__college-cards {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__no-colleges {
    padding: 80px 0;

    &__message {
      font-style: italic;
      margin-bottom: 20px;
    }
  }
}

.active-college-card {
  display: flex;
  padding: 20px;
  margin: 5px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  height: 270px;
  flex: 0 0 180px;
  cursor: pointer;

  &__name {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
  }

  &__city,
  &__division {
    color: $dorian-gray;
  }

  &__logo-thumbnail {
    max-width: 100px;
    max-height: 100px;
  }

  &__more-actions-menu {
    &__toggle-button {
      padding: 10px;
      margin: -10px;
    }
  }
}

.college-search-widget {
  &__input-wrapper {
    position: relative;
  }

  &__search {
    &-input {
      padding: 10px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      padding-left: 40px;
      width: 100%;
    }

    &-icon {
      position: absolute;
      font-size: 20px;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }

  &__map-wrapper {
    path {
      &:hover {
        cursor: pointer;

        &:not([fill="#{$moss}"]) {
          fill: transparentize($moss, 0.3);
        }
      }
    }
  }

  &__state-tooltip {
    position: absolute;
    padding: 8px;
    color: white;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
    transform: translateX(-50%) translateY(-100%);
    transition: opacity 150ms ease-out;
  }
}

.updates-feed-widget {
  &__row {
    margin: 0 -20px;
    padding: 20px;

    // TODO: Remove overrides
    .profile-image.image-xs {
      width: 50px;
      height: 50px;
    }

    &__event-details {
      margin-left: 10px;
    }

    &__athlete-name,
    &__college-name {
      font-weight: bold;
    }

    &__event-date {
      margin-left: 5px;
      color: $dorian-gray;
      font-size: 14px;
    }

    &__event-description {
      font-weight: light;
    }

    &__action-wrapper {
      margin-top: 10px;
    }

    &__remove-recommendation {
      color: #333;
      border-color: #333;

      &:hover {
        background: #333;
        border-color: #333;
      }
    }

    &.is-pending {
      opacity: 0.3;
      border-color: rgba(0, 0, 0, 0.3) !important;
    }

    &:not(:first-child) {
      border-top: 2px solid rgba(0, 0, 0, 0.1);
    }
  }
}
