$activity-row-border: 1px solid rgba(0, 0, 0, 0.15);

.activity-page {
  padding: 32px;

  @include on-mobile {
    padding: 16px;
  }

  &__team-dropdown-toggle-button {
    margin-left: 10px;

    &.btn {
      &:hover {
        color: $dark-gray;
        background: white;
      }
    }

    &__icon {
      transition: transform 150ms ease-out;
      margin-left: 4px;

      .dropdown-menu--is-open & {
        transform: rotate(180deg);
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

// TODO: We could reuse this tab style elsewhere
.activity-page-tab-nav {
  display: flex;
  margin: 32px 0 16px 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #e2e2e2;

  &__tab {
    color: #d0d0d0;
    padding: 8px 32px;
    margin-bottom: -1px;
    font-size: 1.33em;
    border-bottom: 2px solid transparent;
    transition: color 150ms ease-out, border-bottom-color 150ms ease-out;
    cursor: pointer;

    &--is-active {
      color: $color-primary;
      border-bottom-color: $color-primary;
    }

    &:not(:first-child) {
      margin-left: 4px;
    }
  }
}

.athlete-activity-row {
  &:hover {
    cursor: pointer;
  }

  &__athlete-details {
    align-self: flex-start;
  }

  &__athlete-name {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 4px;
  }

  &__team-and-position {
    color: #666;
  }

  &__membership-badge {
    margin-top: 4px;
    font-weight: bold;

    &__icon {
      margin-right: 4px;
    }
  }

  &__more-actions-btn {
    padding: 16px;
  }

  &__pending-status-badge {
    display: block;
    font-size: 12px;
    font-weight: bold;
    color: $color-primary;
    width: 55px; // TODO: Try to remove magic numbers
    text-align: center;
  }

  &__cell {
    padding: 8px;
    border-top: $activity-row-border;
    border-bottom: $activity-row-border;

    &:first-child {
      border-left: $activity-row-border;
      // Hack to make the cell shrink to fit the content
      width: 0.1%;
      white-space: nowrap;
    }

    &:last-child {
      border-right: $activity-row-border;
      // Hack to make the cell shrink to fit the content
      width: 0.1%;
      white-space: nowrap;
    }
  }

  &--is-pending {
    .athlete-activity-row__cell {
      border-color: rgba(0, 0, 0, 0.05);

      &:not(:last-child) {
        & > * {
          opacity: 0.3;
        }
      }
    }
  }
}

.activity-table {
  margin-top: 16px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;

  &__header {
    margin: 0 16px;

    &__cell {
      font-weight: bold;

      // TODO: Clean this up. This is kinda a hacky way of getting things properly centered.
      &:not(.activity-table__header__cell--name) {
        > .flex-table__header__cell {
          justify-content: center;
        }
      }
    }
  }
}

.mobile-activity-table {
  &__sort-toggle {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      width: 100%;
    }

    &__btn {
      display: flex;
      align-items: center;
    }

    &__icon {
      margin-left: 4px;
    }
  }

  &__row {
    padding: 8px;
    margin: 8px 0;
    border: $activity-row-border;

    // TODO: This is pretty gross.. I'm not really sure if there's another way we can achieve this effect?
    &--is-pending {
      border-color: rgba(0, 0, 0, 0.05);

      > .flex-box > *:not(.mobile-activity-table__more-actions) {
        opacity: 0.3;
      }
    }
  }

  &__athlete-details {
    padding-left: 8px;
    padding-bottom: 12px;
  }

  &__profile-percentage {
    width: calc(100% - 16px);
    margin-top: 8px;
  }

  &__header-cell {
    color: #aaaaaa;
  }
}
