.college-profile {
  background-image: url(#{$assetPath}/backgrounds/diamond-gray.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 30px;
  color: #fff;
  max-width: 100vw;
  h2 {
    margin: 0;
    margin-bottom: 1rem;
  }
  .college-details {
    display: flex;
    .profile-image {
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
    .stats {
      margin-bottom: 1rem;
    }
  }
  .box-group {
    display: flex;
    margin-bottom: 2rem;
    .box {
      width: 50%;
      padding: 10px;
      font-size: 1rem;
      text-transform: uppercase;
      background-color: rgba(255, 255, 255, 0.75);
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
      color: #333;
      transition: $transition-default;
      &:not(:last-of-type) {
        margin-right: 10px;
      }
      .college {
        tr {
          th {
            width: 55%;
          }
          th,
          td {
            vertical-align: top;
            padding-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

.college-coaches {
  padding: 30px;
  background-color: #eee;
  .coach-change {
    float: right;
    margin-top: -2.5em;
  }
}

@media screen and (max-width: 991px) {
  .college-profile {
    .box-group {
      display: table;
      .box {
        display: table-cell;
      }
    }
  }
  .college-coaches {
    max-width: 100vw;
    box-sizing: border-box;
    .coach-change {
      margin-bottom: 1em;
      margin-top: 0;
      float: none;
    }
    tr {
      display: block;
      max-width: 90vw;
      border-top: 1px solid $border_color;
      padding: 1em;
      &:first-of-type {
        display: none;
        border: none;
      }
    }
    .table > tbody > tr > td {
      display: block;
      width: 100%;
      border: 0;
      line-height: 1.4em;
      padding: 0;
      &:first-of-type,
      &:nth-of-type(2) {
        font-weight: bold;
      }
    }
  }
  main {
    .section-content {
      max-width: 100vw;
    }
  }
  .team-filter-dropdown {
    .row {
      margin: 0 0.8em;
    }
  }
  .filter-teams {
    .table--sortable-header {
      display: none;
    }
  }
}
