.athlete-profile {
  background-image: url(#{$assetPath}/backgrounds/diamond.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  padding: 60px 30px;

  h2 {
    margin: 0;
    margin-bottom: 1rem;
    color: #fff;
    width: 285px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .athlete-details {
    display: flex;

    .profile-image {
      margin-right: 2rem;
      margin-bottom: 2rem;
    }

    .stats {
      margin-bottom: 1rem;
      width: 285px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .rating {
        font-size: 2rem;
        margin-bottom: 1rem;
        vertical-align: text-top;
        overflow: hidden;

        .rating-star {
          color: $color-secondary;
          float: left;
          margin-right: 5px;
        }
      }
    }
  }

  .box-group {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    color: #333;

    .box {
      font-weight: 700;

      &:hover {
        background-color: $color-primary;
        cursor: pointer;
      }

      &:not(:last-of-type) {
        margin-right: 10px;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 33.3%;
      height: 100px;
      padding: 10px;
      font-size: 1rem;
      text-transform: uppercase;
      background-color: rgba(255, 255, 255, 0.75);
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
      transition: all 0.1s ease-in-out;
      text-align: center;

      .fa-check {
        color: #3c763d;
        font-size: 1.25rem;
      }

      .fa-times {
        color: #a94442;
        font-size: 1.25rem;
      }

      table {
        th {
        }

        th,
        td {
          padding: 2px;
        }
      }
    }
  }

  .btn-group {
    margin-bottom: 0.8em;
  }

  .profile-completion-percentage {
    height: 45px;
    text-transform: uppercase;
    font-size: large;
    font-family: $default-font-family;

    .horizontal-bar {
      height: 10px;
      background-color: rgba(255, 255, 255, 0.75);
      border-radius: 5px;

      .filling {
        height: 10px;
        background-color: $color-secondary;
        border-radius: 5px;
      }
    }
  }

  .evaluation {
    .rating {
      border: none;
      float: left;
      overflow: hidden;
    }

    .rating > input {
      display: none;
    }
    .rating > label:before {
      margin: 5px;
      font-size: 1.25em;
      font-family: "Font Awesome 5 Pro";
      font-weight: 600;
      display: inline-block;
      content: "\f005";
    }

    .rating > .half:before {
      content: "\f089";
      position: absolute;
    }

    .rating > label {
      color: #ddd;
      float: right;
    }

    .rating > input:checked ~ label,
    .rating:not(:checked) > label:hover,
    .rating:not(:checked) > label:hover ~ label {
      color: #ffd700;
    }

    .rating > input:checked + label:hover,
    .rating > input:checked ~ label:hover,
    .rating > label:hover ~ input:checked ~ label,
    .rating > input:checked ~ label:hover ~ label {
      color: #ffed85;
    }
  }
}

.evaluation-chart {
  thead,
  tbody {
    tr {
      cursor: pointer;

      td,
      th {
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
      }

      th:nth-child(1) {
        width: 125px;
      }

      th:nth-child(2) {
        width: 225px;
      }

      th:nth-child(3) {
        width: auto;
      }
    }
  }

  tbody {
    tr:not(:first-of-type) {
      th,
      td {
        height: 75px;
      }
    }
  }
}

#map {
  width: 410px;
  height: 275px;
}

.location-preferences {
  list-style: none;
  padding: 0;
  margin: 0;

  .location-blue {
    color: $color-primary;
  }

  .location-orange {
    color: $color-secondary;
  }

  .location-default {
    color: #000;
  }
}

@media (max-width: 767px) {
  .athlete-profile {
    padding: 20px;
    max-width: 100vw;

    .btn-group {
      .btn {
        &:nth-child(2) {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &:last-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          margin: 0.1em 0 1em;
        }
      }
    }
  }
}

// Athlete Profile Evaluation

.view-message {
  margin-left: 0.5em;
}

.star-rating {
  display: block;

  &.star-rating--disabled .rating {
    opacity: 0.5;

    * {
      cursor: not-allowed;
    }
  }
}

// Profile Incomplete Items

.profile-missing-js {
  background: rgba(0, 0, 0, 0.4);
  padding: 1em 1.5em;
  -webkit-transition: height 0.2s linear, padding-top 0.2s linear,
    padding-bottom 0.2s linear, opacity 0.2s linear;
  transition: height 0.2s linear, padding-top 0.2s linear,
    padding-bottom 0.2s linear, opacity 0.2s linear;

  &.toggle {
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    height: 0;
    opacity: 0.5;
  }

  h4 {
    text-transform: uppercase;
  }
}

.missing-js {
  color: #fff;

  &:hover {
    color: $dorian_lighter;
    cursor: pointer;
    text-decoration: none;
  }
}
