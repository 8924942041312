.app-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &__main {
    flex: 1 0 auto;
  }
}

main {
  background-color: #fff;
  min-height: 600px;

  .heading {
    text-transform: uppercase;
    color: $color-primary;
  }

  .events {
    color: $dorian-gray;
    margin: 0;
    margin-bottom: 3rem;
    padding: 0;
    list-style: none;

    li:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  .achievements {
    color: $dorian-gray;

    td:first-of-type {
      font-weight: 700;
    }

    td {
      padding-right: 5px;
      padding-bottom: 1rem;
    }
  }

  .graphs {
    background-color: #eee;
    padding: 10% 0;
    min-height: 400px;

    @include respond-to(screen) {
      padding: 2em;
    }

    div:first-of-type {
      max-width: 95%;
      margin: 0 auto;

      @include respond-to(screen) {
        max-width: 100%;
      }
    }

    .row {
      margin: 0;
    }

    .col-lg-12 {
      p {
        margin: 0 0 20px;
        color: $slate;
        font-style: italic;
      }
    }
  }

  .section-content {
    padding: 30px;

    h3 {
      .small {
        font-size: 1rem;
        color: $dorian-gray;
      }
    }

    &.section-content--dashboard {
      .card-sort {
        @media (min-width: 768px) {
          margin-top: -2.5rem;
        }
      }
      .table.table--sortable-header {
        thead {
          @media (min-width: 768px) {
            width: 400px;
          }
          th {
            @media (min-width: 768px) {
              width: 27%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #dashboard-chart {
    display: none;
  }

  main {
    padding-top: 60px;

    .graphs {
      min-height: auto;
      padding: 2rem;
      width: 100%;

      .dashboard-caption {
        display: none;
      }
    }

    .section-content {
      padding: 20px;
    }
  }
}

@media (min-width: 900px) {
  main {
    .graphs {
      display: block;
    }
  }
}

.onboarding-panels {
  background-color: #eee;

  h1 {
    font-weight: bold;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 1em;
  }

  a.demo-link {
    text-decoration: underline;
  }
}
