.notifications {
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  height: 0;

  .notification {
    padding: 30px;
    text-align: center;
    background-color: rgba(51, 86, 135, 0.9);
    color: #fff;
    transform: translateY(100%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }

    &:not(:first-of-type) {
      margin-bottom: 0.5rem;
    }
  }
}
