.percentage-bar {
  position: relative;
  height: 12px;
  width: 100%;
  min-width: 100px;
  border: 1px solid #ddd;

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
