.admin-dashboard {
  .admin-dashboard-filters {
    padding: 3rem 0;
    border-bottom: 1px solid $border_color;
    margin-bottom: 1rem;
  }

  .section-heading {
    font-size: 15px;
    font-weight: bold;
    padding: 15px;
    background-color: $color-primary;
    margin: 1rem 0 2rem;
    color: #fff;

    @include respond-to(screen) {
      font-size: 20px;
    }
  }
}
