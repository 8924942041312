.simple-tooltip {
  $tooltip-background-color: rgba(0, 0, 0, 0.8);

  position: relative;
  padding: 5px;

  &:hover {
    .simple-tooltip__tip,
    .simple-tooltip__container {
      opacity: 1;
    }
  }

  &__container {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    border-radius: 3px;
    background-color: $tooltip-background-color;
    color: white;
    white-space: nowrap;
    transition: opacity 150ms ease-out;
  }

  &__tip {
    opacity: 0;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $tooltip-background-color;
    transition: opacity 150ms ease-out;
  }
}
