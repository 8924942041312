.table {
  border-spacing: 0;
  thead > tr > th {
    border-bottom: 1px solid $dorian-gray;
  }
  tbody > tr > td {
    padding: 10px 5px;
  }
  tbody > tr:first-of-type > th,
  tbody > tr:first-of-type > td {
    border: 0;
  }
  &.table--sortable-header {
    th {
      position: relative;
      font-weight: 400;
      color: $dorian-gray;
      white-space: nowrap;
      cursor: pointer;
      &.sort {
        font-weight: 700;
        color: #000;
        .table--sortable-header__sort-icon {
          position: absolute;
          display: inline-block;
          margin-left: 5px;
          font-size: 10px;
          transition: all 0.1s ease-in-out;
          vertical-align: text-bottom;

          &--is-reversed {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  &.table--administration {
    th {
      white-space: nowrap;
    }
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 140px;
    }
  }
  &.table--administration {
    @media (max-width: 767px) {
      border-collapse: collapse;
      thead {
        display: none;
      }
      tr {
        border-bottom: 1px solid $border_color;
        border-top: 1px solid $border_color;
      }
      > tbody > tr > td {
        border: 0;
      }
      td {
        max-width: 100%;
        padding: 0 0 1em 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &:first-of-type {
          padding-top: 1em;
          font-weight: bold;
        }
        &:nth-of-type(3) {
          padding-bottom: 1em;
        }
        &:not(.invite-single-modal) {
          display: inline-block;
          width: 60vw;
        }
        &.text-right {
          padding-top: 1em;
          width: 5vw;
        }
        &.invite-single-modal {
          width: 35vw;
        }
      }
    }
  }
}
