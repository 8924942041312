.alert {
  &.alert-info {
    color: #fff;
    background-color: $color-primary;

    .spinner {
      border-left-color: #31708f;
    }
  }

  .spinner,
  .spinner:after {
    width: 16px;
    height: 16px;
  }

  .spinner {
    margin-right: 10px;
    display: inline-block;
    border-style: solid;
    border-width: 2px;
    border-top-color: rgba(255, 255, 255, 0.8);
    border-right-color: rgba(255, 255, 255, 0.8);
    border-bottom-color: rgba(255, 255, 255, 0.8);
    margin-bottom: -2px;
  }
}
