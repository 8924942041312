main.public-page {
  padding-top: 0;
}

.team-profile {
  background-image: url(#{$assetPath}/backgrounds/public-profile/multi-sport.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0;
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: 760px),
    (max-device-width: 768px) and (min-device-width: 1024px) {
    padding: 60px 30px;
  }

  &.bg-baseball {
    background-image: url(#{$assetPath}/backgrounds/public-profile/baseball.png);
  }

  &.bg-mens-basketball,
  &.bg-womens-basketball {
    background-image: url(#{$assetPath}/backgrounds/public-profile/basketball.png);
  }

  &.bg-field-hockey {
    background-image: url(#{$assetPath}/backgrounds/public-profile/fieldhockey.png);
  }

  &.bg-football {
    background-image: url(#{$assetPath}/backgrounds/public-profile/football.png);
  }

  &.bg-mens-golf,
  &.bg-womens-golf {
    background-image: url(#{$assetPath}/backgrounds/public-profile/golf.png);
  }

  &.bg-mens-ice-hockey,
  &.bg-womens-ice-hockey {
    background-image: url(#{$assetPath}/backgrounds/public-profile/hockey.png);
  }

  &.bg-mens-lacrosse,
  &.bg-womens-lacrosse {
    background-image: url(#{$assetPath}/backgrounds/public-profile/lacrosse.png);
  }

  &.bg-mens-rowing,
  &.bg-womens-rowing {
    background-image: url(#{$assetPath}/backgrounds/public-profile/rowing.png);
  }

  &.bg-mens-soccer,
  &.bg-womens-soccer {
    background-image: url(#{$assetPath}/backgrounds/public-profile/soccer.png);
  }

  &.bg-softball {
    background-image: url(#{$assetPath}/backgrounds/public-profile/softball.png);
  }

  &.bg-mens-swimming,
  &.bg-womens-swimming {
    background-image: url(#{$assetPath}/backgrounds/public-profile/swimming.png);
  }

  &.bg-mens-tennis,
  &.bg-womens-tennis {
    background-image: url(#{$assetPath}/backgrounds/public-profile/tennis.png);
  }

  &.bg-mens-track,
  &.bg-womens-track {
    background-image: url(#{$assetPath}/backgrounds/public-profile/track-cc.png);
  }

  &.bg-mens-volleyball,
  &.bg-womens-volleyball {
    background-image: url(#{$assetPath}/backgrounds/public-profile/volleyball.png);
  }

  &.bg-mens-water-polo,
  &.bg-womens-water-polo {
    background-image: url(#{$assetPath}/backgrounds/public-profile/waterpolo.png);
  }

  &.bg-wrestling {
    background-image: url(#{$assetPath}/backgrounds/public-profile/wrestling.png);
  }

  .stats {
    h2 {
      text-decoration: none;
    }
  }

  .college-details {
    width: 100%;
    margin: auto;
    background-color: #fff;
    text-align: center;
    padding: 2.5em 1em;

    @media only screen and (min-width: 760px),
      (max-device-width: 768px) and (min-device-width: 1024px) {
      width: 320px;
    }
  }

  .profile-image {
    border-radius: 0;
    border: none;
  }

  .club-logo {
    max-width: 150px;
  }

  .club-logo-img {
    max-width: 300px;
  }

  .profileState,
  .profileEmail {
    display: block;
    font-weight: bold;
  }

  .profileContact {
    margin-top: 1rem;
  }

  a.corner-ribbon {
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
    background-color: $color-secondary;
    text-transform: uppercase;
    width: 200px;
    position: absolute;
    text-align: center;
    line-height: 1;
    color: #f0f0f0;
    top: auto;
    right: -50px;
    bottom: 25px;
    left: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    padding: 10px;
    cursor: pointer;
    font-size: 1.2em;

    &:hover.corner-ribbon {
      text-decoration: none;
    }
  }
}

.public-roster {
  a {
    cursor: pointer;
  }

  .table--sortable-header {
    display: none;
    border-bottom: 0;
    margin-bottom: 0;

    @media only screen and (min-width: 991px) {
      display: block;
    }

    thead {
      width: 100%;
      margin-right: 0;
      border-collapse: collapse;

      th {
        text-align: left;
        white-space: nowrap;
        font-weight: bold;
        font-size: 1.1rem;
        padding: 8px 2px;

        @media only screen and (min-width: 1199px) {
          font-size: 1.4rem;
        }
      }
    }
  }

  table {
    @media only screen and (min-width: 991px) {
      // Roster Sort Columns
      #lastName,
      td:first-child {
        width: 28%;
      }

      #gradYear,
      td:nth-child(2) {
        width: 8%;
      }

      #position,
      td:nth-child(3) {
        width: 10%;
      }

      #teamName,
      td:nth-child(4) {
        width: 22%;
      }

      #videoUpdatedDate,
      td:nth-child(5),
      #gpa,
      td:nth-child(6),
      #keyStatsTotal,
      td:nth-child(7),
      #transcript,
      td:nth-child(8),
      #collegeCommitment,
      td:nth-child(9) {
        width: 6%;
      }
    }
  }

  // Roster Data
  .table {
    a {
      color: #335687;
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    label {
      font-weight: normal;

      &.team-name {
        color: inherit;
      }
    }

    tbody {
      tr:nth-child(odd) {
        background-color: #eee;
      }

      & > tr > td {
        padding: 8px 5px;
      }
    }

    td {
      @media only screen and (min-width: 760px),
        (max-device-width: 768px) and (min-device-width: 1024px) {
        border-top: 1px solid #ccc;
        border-left: 1px solid #e7e7e7;
        border-right: 1px solid #ccc;
      }
    }

    tr:first-child {
      td {
        @media only screen and (min-width: 760px),
          (max-device-width: 768px) and (min-device-width: 1024px) {
          border-top: 1px solid #ccc;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
        }
      }
    }

    tr:last-child {
      td {
        @media only screen and (min-width: 760px),
          (max-device-width: 768px) and (min-device-width: 1024px) {
          border-bottom: 1px solid #ccc;
        }
      }
    }

    // Base for label styling
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      position: absolute;
      left: -9999px;
    }

    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
      position: absolute;
      left: 35%;

      @media only screen and (min-width: 760px),
        (max-device-width: 768px) and (min-device-width: 1024px) {
        position: relative;
        left: auto;
      }
    }

    // checkbox aspect
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      width: 1.25em;
      height: 1.25em;
      border: 0;
      background: transparent;
    }

    // checked mark aspect
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      content: "✔";
      position: absolute;
      top: 6px;
      left: 0;
      margin: auto;
      font-size: 1.3em;
      line-height: 0.8;
      color: $color-secondary;
      transition: all 0.2s;
    }

    // checked mark aspect changes
    [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }

    [type="checkbox"]:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    // Forcing table to not be like tables anymore
    table,
    tbody,
    th,
    td,
    tr {
      display: block;
      padding-bottom: 25px;
    }

    tr .public-roster &,
    tr:first-child .public-roster &,
    td .public-roster & {
      border: none;
    }

    table.table > tbody > tr > {
      td {
        width: auto;
        border: none;
        border-bottom: none;
        position: relative;

        // Name, Grad Year, Position, & Team
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          padding: 3px 6px 0 13px;
          display: inline-table;
          width: 50%;
        }

        &:nth-of-type(4) {
          label {
            margin-right: 8px;
          }
        }

        // Video, GPA, Key Stats, Transcript, & Committed
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8),
        &:nth-of-type(9) {
          display: inline-table;

          label {
            position: absolute;
          }
        }

        &:before {
          position: relative;
          top: auto;
          left: 6px;
          width: auto;
          padding-right: 10px;
          white-space: nowrap;
          font-weight: bold;
          font-size: 0.8em;
          color: $color-secondary;
        }

        &:nth-of-type(3):before,
        &:nth-of-type(4):before {
          color: $dark-gray;
          font-size: 1em;
        }

        // Label the data
        &:nth-of-type(5):before {
          content: "Video ";
        }

        &:nth-of-type(6):before {
          content: "GPA ";
        }

        &:nth-of-type(7):before {
          content: "Key Stats ";
        }

        &:nth-of-type(8):before {
          content: "Transcript ";
        }

        &:nth-of-type(9):before {
          content: "Committed ";
        }
      }
    }
  }
}
