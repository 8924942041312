.link-button {
  color: $color-tertiary;
  cursor: pointer;

  @at-root {
    a#{&} {
      text-decoration: none;
    }
  }

  > a {
    text-decoration: none;
  }
}
