.flex-table {
  &__header {
    &__cell {
      &__sort-icon {
        $transition-timing: 150ms ease-out;
        opacity: 0;
        margin-left: 4px;
        transition: transform $transition-timing, opacity $transition-timing;

        &--is-ascending {
          transform: rotate(0deg);
        }

        &--is-descending {
          transform: rotate(180deg);
        }

        &--is-active {
          opacity: 1;
        }
      }

      &--is-sortable {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
