.dropdown-menu {
  position: relative;

  &__toggle-button {
    cursor: pointer;
  }

  &__menu {
    display: none;
    position: absolute;
    padding: 4px 0;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    max-height: 85vh;
    overflow-y: auto;
    // TODO: Settings arbitrary z-indices is pretty bad. There are a bunch of
    // solutions to this.
    z-index: 100;

    &--is-open {
      display: block;
    }
  }

  &__item {
    display: block;
    padding: 8px 16px;
    // TODO: Put colors somewhere. Ideally we'll define a color palette and use
    // that everywhere. One-off colors can be pretty hard to hunt down.
    color: $color-primary;
    white-space: nowrap;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &--is-active {
      background: rgba(0, 0, 0, 0.05);
      text-decoration: none;
    }
  }

  &__divider {
    margin: 4px 0;
    height: 1px;
    background: rgba(0, 0, 0, 0.15);
  }
}
