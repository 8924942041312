.form-group {
  &.has-error {
    .status {
      display: block;
      color: #a94442;
      margin-top: 0.5rem;
    }
  }

  .status {
    display: none;
  }
}

.form-control {
  height: 40px;
  border-radius: 0;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
}

.form-inline {
  label {
    margin-right: 5px;
  }

  .search-input {
    display: inline-block;
  }

  .btn,
  button,
  input[type="button"],
  input[type="submit"] {
    vertical-align: middle;
  }
}

.radio,
.checkbox {
  margin-top: 0;
}

select.form-control {
  background-color: #fff;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  padding-right: 25px;
}

.search-input {
  position: relative;

  &:before {
    position: absolute;
    left: 10px;
    top: 10px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f002";
    color: $dorian-gray;
  }

  .form-control {
    padding-left: 30px;
  }
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;

  .form-control {
    display: table-cell;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
    display: table-cell;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;

    .btn {
      z-index: 2;
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border: 1px solid transparent;
      position: relative;
      font-size: 1.3rem;
    }
  }
}

textarea {
  min-height: 200px;
  width: 100%;
}

.schedule-fields {
  padding-left: 5px;

  .control-label {
    color: #777;
  }
}
