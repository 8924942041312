.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 10px 0;

  .cards-inner {
    flex: 1;
    position: relative;

    .get-data-indicator {
      .get-data-indicator-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background-color: white;
        opacity: 0.9;
        z-index: 10001;
      }

      .alert {
        position: relative;
        z-index: 10002;
      }
    }
  }

  .card {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    background-color: #fff;
    transition: all 0.1s ease-in;

    a {
      display: block;
      text-decoration: none;
      cursor: pointer;
      width: 100%;
      color: inherit;
    }

    &:not(.free-user):hover {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    }

    &.selected {
      background-color: #eee;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
    }

    &.disabled {
      cursor: not-allowed;

      a {
        pointer-events: none;
      }
    }

    &.card-basic {
      display: inline-flex;
      width: 270px;
      min-height: 85px;
      margin-right: 1rem;

      .card-content {
        .card-details {
          h4 {
            width: 175px;
          }

          .subtitle {
            width: 175px;
          }
        }
      }
    }

    &.card--with-buttons {
      display: flex;
      align-items: start;
      flex-direction: column;

      @include respond-to(tablet) {
        flex-direction: row;
        align-items: center;
      }

      .card-buttons {
        padding: 10px;
        flex: 0 0 auto;
      }
    }

    &.card-extended {
      position: relative;
      width: 100%;
      min-height: 100px;

      &.free-user {
        min-height: 80px;
      }

      .card-content {
        .card-details {
          display: inline-block;

          h4 {
            width: 250px;
          }

          .subtitle {
            width: 250px;

            &.contract-amounts {
              margin-bottom: 0.4em;
            }
          }
        }
      }
    }

    &.card-drawer {
      &.card-athlete {
        .drawer {
          .drawer-toggle {
            background-color: $color-primary;
          }

          .drawer-content {
            background-color: #666;
          }
        }

        .tag-premium {
          background-color: $color-primary;
          color: #fff;
        }

        .tag-team {
          background-color: $moss;
          color: #fff;
        }

        .tag-free {
          background-color: $dorian-gray;
          color: #fff;
        }
      }

      &.card-college {
        .drawer {
          .drawer-toggle {
            background-color: $color-primary;
          }

          .drawer-content {
            background-color: #284c6b;
          }
        }
      }

      .disabled-link {
        cursor: not-allowed;
        opacity: 0.5;
      }

      .card-content {
        padding-right: 35px;
      }

      .drawer {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 100%;
        transition: all 0.5s ease-in-out;

        &.active {
          width: 100%;

          .drawer-toggle {
            opacity: 1;

            &__icon {
              transform: rotate(180deg);
            }
          }

          .drawer-content {
            visibility: visible;
            opacity: 0.9;
          }
        }

        .drawer-toggle {
          position: absolute;
          top: 0;
          right: 0;
          width: 25px;
          height: 100%;
          z-index: 1000;
          background-color: $dark-gray;
          opacity: 0.75;
          cursor: pointer;
          transition: all 0.15s ease-in-out;

          &__icon {
            position: absolute;
            top: 50%;
            right: 7px;
            margin-top: -7px;
            color: #fff;
            transition: all 0.15s ease-in-out;
          }
        }

        .drawer-content {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $dorian-gray;
          position: absolute;
          left: 0;
          right: 25px;
          height: 100%;
          z-index: 1000;
          color: #fff;
          padding: 10px;
          visibility: hidden;
          opacity: 0;
          transition: all 0.5s ease-in-out;

          .drawer-options {
            list-style: none;
            padding: 0;
            margin: 0;

            .drawer-item {
              float: left;
              text-align: center;
              font-size: 1rem;
              text-transform: uppercase;
              width: 90px;
              margin-right: 10px;

              &:hover {
                cursor: pointer;
              }

              .icon {
                font-size: 3rem;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    .card-content {
      display: flex;
      align-items: center;
      padding: 10px;

      .card-profile {
        display: flex;
        width: 100%;

        .profile-image {
          margin-right: 1rem;
        }

        .card-details {
          flex-shrink: 1;
          width: 70%;
        }

        .org-details {
          display: flex;

          .category-title {
            color: #bbb;
            font-size: 1.25rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .affiliated-admins {
            max-height: 100px;
            overflow: scroll;
          }
        }

        .self-provision-text {
          color: #bbb;
          font-size: 1.25rem;
        }
      }

      .card-image-group {
        position: relative;
        width: 80px;
        height: 80px;
        margin-right: 10px;

        .card-image-top,
        .card-image-bottom {
          display: flex;
          justify-content: center;
          position: absolute;
          width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 50%;
          border: 1px solid #ccc;

          img {
            height: inherit;
          }
        }

        .card-image-top {
          top: 0;
          left: 0;
        }

        .card-image-bottom {
          bottom: 0;
          right: 0;
        }
      }

      .card-details {
        h4 {
          margin-bottom: 0.25rem;
          color: $color-tertiary;
          font-weight: 700;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .athlete {
            color: #000;
          }
        }

        .tag {
          margin-left: 0.1em;
          vertical-align: top;

          &.public {
            background: $slate;
          }

          &.type {
            background: $deep-water;
          }
        }

        .tag-group {
          display: inline-block;
          vertical-align: super;
        }

        .subtitle {
          color: #bbb;
          font-size: 1.25rem;
          margin-bottom: 0.75rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .committment {
          color: $color-secondary;
          font-size: 1.25rem;
        }
      }

      .card-date {
        margin-left: auto;
        color: $dorian-gray;
        font-size: 1.25rem;
      }

      .college-details {
        .fa {
          font-size: 3rem;
        }
      }

      .card-table {
        margin-left: auto;
        flex-shrink: 0;

        th {
          font-size: 1rem;
          width: 19%;
          color: $dorian-gray;
          text-transform: uppercase;
          font-weight: 400;
          vertical-align: bottom;
        }

        td {
          font-size: 2rem;
          font-weight: 700;

          &.small {
            font-size: 1.5rem;
          }
        }

        th,
        td {
          text-align: center;
        }

        .ncsa-match-card {
          background-color: $dorian-gray;
          color: #fff;
        }

        .ncsa-match {
          background-color: $color-secondary;
          color: #fff;
        }
      }
    }
  }
}

.card-sort {
  margin-bottom: 2rem;

  & ~ .cards {
    margin: 0;
  }

  &.card-sort-drawer {
    padding-right: 25px;
  }

  .table--sortable-header {
    border-bottom: 1px solid #ccc;

    thead {
      float: right;
      margin-right: 3rem;
      margin-bottom: 1rem;
    }

    th {
      font-size: 1rem;
      border-bottom: 0;
      padding: 1rem 2rem;
      text-transform: uppercase;
      text-align: center;
      vertical-align: bottom;
    }
  }
}

@media (max-width: 1199px) {
  .cards {
    .card {
      &.card-basic {
        width: 315px;
      }

      &.card-extended {
        .card-content {
          flex-wrap: wrap;

          .card-profile {
            display: flow-root;
          }

          .card-table {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .cards {
    .card {
      &.card-basic {
        width: 100%;
      }

      &.card-extended {
        .card-content {
          flex-wrap: wrap;

          .card-profile {
            display: flow-root;

            .org-details {
              display: block;
            }
          }

          .card-table {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

// Target List Cards

#react-tabs-3 {
  .free-user {
    .card-table {
      th {
        opacity: 0.7;
      }

      .premium-access {
        font-size: 1.2em;
        color: $dorian-gray;
        font-weight: normal;

        span {
          margin-right: 0.5em;
        }
      }
    }
  }
}

// Organization Cards

.org-card {
  .profile-image {
    &.image-xs {
      width: auto;
      max-width: 100px;
      height: auto;
      border-radius: 0;
      display: inline-block;
    }

    img {
      height: auto;
      width: 100%;
      max-width: 100%;
      margin-top: 15%;
    }
  }
}
