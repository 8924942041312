.landing-page {
  height: 100%;
  background-color: $light-gray;
  display: flex;
  flex-direction: column;

  header {
    flex: 0;
    .logo {
      display: inline-block;
      margin-top: 10px;
      margin-left: 15px;
      img {
        height: 40px;
        padding-right: 10px;
        border-right: 1px solid #fff;
      }
    }
  }

  .container {
    padding: 0 15px;
  }

  h1 {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.16;
    margin: 0 0 3rem;
    z-index: 1;

    @media (min-width: 768px) {
      font-size: 2.8rem;
    }

    @media (min-width: 991) {
      font-size: 3.6rem;
    }
  }

  h2 {
    margin: 0;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.5;
  }

  .subhead {
    font-size: 1.8rem;
    line-height: 1.5;
    margin: 1rem 0;
  }

  .colored {
    &--slate {
      background: $slate;
    }

    &--deep-water {
      background: $deep-water;
    }
  }

  img {
    width: 100%;
    display: block;
    height: auto;
  }

  header {
    background-color: #f0efee;
    padding: 1em 0;
    height: auto;
    position: static;

    .logo {
      margin: 0;
      max-width: 85px;

      @media (min-width: 768px) {
        max-width: 200px;
      }

      img {
        border: 0;
        padding: 0;
        margin: 0;
        margin-top: 5px;
        height: auto;
        width: 100%;

        @media (min-width: 768px) {
          width: 100%;
        }

        @media (min-width: 991px) {
          width: 100%;
        }
      }
    }

    .header__content {
      padding-top: 0.5rem;

      @media (min-width: 768px) {
        padding-top: 1.5rem;
      }

      @media (min-width: 991px) {
        padding-top: 2rem;
      }

      form {
        float: left;
        margin-right: 1rem;

        label {
          display: none;
        }

        .form-group {
          float: left;
          margin: 0;
          margin-right: 1rem;

          input {
            width: 160px;

            @media (min-width: 991px) {
              width: 190px;
            }
          }
        }

        .form-buttons-row {
          float: left;
          width: auto;
          padding: 0;
          margin: 0;
        }

        .form-buttons {
          padding: 0;
        }
      }

      .btn {
        margin-top: 0.1rem;
      }

      .forgot-password {
        clear: both;
        text-align: left;
        padding: 0.75rem 0 0;

        a {
          color: #000;
        }
      }
    }
  }

  header + .alert {
    margin-bottom: 0;
  }

  .landing-content {
    flex: 0;
  }

  .landing-page-hero {
    position: relative;

    &__video-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: $dark-gray;
      overflow: hidden;
    }

    &__video {
      min-width: 100%;
      min-height: 100%;
      opacity: 0.25;
    }

    &__content-container {
      position: relative;
      background-color: #345686;

      @include respond-to("tablet") {
        background-color: transparent;
      }
    }

    &__content {
      padding: 10px 0;
      color: white;
      font-size: 16px;
      flex-direction: column;
      align-items: center;

      @include respond-to("tablet") {
        padding: 50px;
        font-size: 18px;
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &__content-left {
      align-items: center;
      text-align: center;

      @include respond-to("tablet") {
        padding-right: 40px;
        align-items: flex-start;
        text-align: left;
      }
    }

    &__content-right {
      align-items: center;

      @include respond-to("tablet") {
        align-items: flex-start;
      }
    }

    &__title {
      font-size: 2.75rem;
      margin-bottom: 20px;

      @include respond-to("tablet") {
        font-size: 3.75rem;
        margin-bottom: 40px;
      }
    }

    &__cta {
      font-size: 1.15em;
      margin: 20px 0;

      @include respond-to("tablet") {
        font-size: 1.5em;
      }
    }

    &__learn-more-text {
      font-size: 0.85em;
      padding: 0 15%;
      margin-bottom: 20px;

      @include respond-to("tablet") {
        padding: 0;
        margin-botton: 0;
      }
    }

    &__learn-more-link {
      color: #fff;
    }
  }

  .talking-point {
    font-size: 1em;
    margin-bottom: 15px;
    text-align: center;

    @include respond-to("tablet") {
      font-size: 24px;
      margin-bottom: 40px;
      text-align: left;
    }

    @include respond-to("screen") {
      font-size: 28px;
      margin-bottom: 40px;
      text-align: left;
    }

    &__title {
      display: block;
      font-weight: bold;
    }
  }

  footer {
    flex: 1;
    color: #fff;
    background-color: #006fba;
    padding: 2rem;

    .sub {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}
