.container {
  padding: 0;
}

@media (max-width: 991px) {
  .container {
    width: 100%;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.m-2 {
  margin: 2rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.m-3 {
  margin: 3rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}
