footer {
  flex: 0;
  color: #fff;
  font-size: 1.25rem;
  background-color: $color-primary;

  a {
    color: #fff;
    font-weight: 700;
    &:hover {
      color: #fff;
    }
  }
  .social,
  .partner-logos {
    padding: 30px;

    &__logo {
      margin: 4px;
    }

    &__logo-image {
      height: 64px;
    }
  }
  .copyright {
    padding: 30px;
    a {
      padding: 0;
    }
  }
  ul {
    li {
      list-style: none;
    }
    &.contact-us {
      padding: 0;
      margin: 0;
      margin-bottom: 2rem;
      li {
        padding: 5px 0;
        &:not(:last-of-type) {
          margin-bottom: 0.5rem;
          border-bottom: 1px solid #ccc;
        }
        .fa {
          margin-right: 10px;
        }
      }
    }
    &.follow-us {
      padding: 0;
      margin: 0;
      margin-bottom: 2rem;
      font-size: 3rem;
      li {
        float: left;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  footer {
    .social {
      padding: 20px;
    }
    .copyright {
      padding: 20px;
    }
  }
}
