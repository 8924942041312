// CORE
@import "core/mixins";
@import "core/variables";
@import "core/fonts";
@import "core/base";

// COMPONENTS
@import "components/alerts";
@import "components/buttons";
@import "components/cards";
@import "components/forms";
@import "components/menus";
@import "components/misc";
@import "components/modals";
@import "components/notifications";
@import "components/pagination";
@import "components/spinners";
@import "components/tags";
@import "components/tables";
@import "components/control-panel";
@import "components/dropdown-menu";
@import "components/mobile-sidebar";
@import "components/flex-box";
@import "components/flex-table";
@import "components/percentage-bar";
@import "components/content-container";
@import "components/simple-tooltip";
@import "components/link-button";

// LAYOUT
@import "layout/header";
@import "layout/main";
@import "layout/footer";

// PAGES
@import "pages/administration";
@import "pages/relationships";
@import "pages/admin-dashboard";
@import "pages/athlete-profile";
@import "pages/college-profile";
@import "pages/team-profile";
@import "pages/find-colleges";
@import "pages/signin";
@import "pages/landing";
@import "pages/activity-page";
@import "pages/dashboard-page";

// MODALS
@import "modals/roster-management-redirect-modal";

//VENDOR
@import "vendor/bootstrap";
@import "vendor/helpscout";
@import "vendor/selectize";

html,
body,
#app {
  height: 100%;
}
