.flex-box {
  display: flex;
  flex-basis: auto;

  &--direction-column {
    flex-direction: column;
  }

  &--direction-row {
    flex-direction: row;
  }

  &--align-start {
    align-items: flex-start;
  }

  &--align-center {
    align-items: center;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--align-baseline {
    align-items: baseline;
  }

  &--align-stretch {
    align-items: stretch;
  }

  &--justify-around {
    justify-content: space-around;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--justify-evenly {
    justify-content: space-evenly;
  }

  &--justify-start {
    justify-content: flex-start;
  }

  &--justify-stretch {
    justify-content: stretch;
  }

  &--wrap {
    &-nowrap {
      flex-wrap: nowrap;
    }

    &-wrap {
      flex-wrap: wrap;
    }

    &-wrap-reverse {
      flex-wrap: wrap-reverse;
    }
  }

  &--block {
    &-block {
      display: block;
    }

    &-inline {
      display: inline-block;
    }
  }
}
