.react-selectize .react-selectize-control {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid #dedcd6;
  font-size: 1em;
  cursor: pointer;
  position: relative;
  padding: 2px;
  height: 40px;
}

.react-selectize .react-selectize-placeholder {
  display: block;
  line-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  color: #aaa;
  text-indent: 8px;
}

.react-selectize .dropdown-menu {
  position: absolute;
  top: 40px;
  width: 100%;
  text-align: left;
  list-style: none;
  background-color: #fff;
  z-index: 100;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.react-selectize .dropdown-menu .option-wrapper.highlight .club-option {
  background-color: #f4f3f4;
  font-weight: normal;
}
.react-selectize .dropdown-menu .club-option {
  display: block;
  width: 100%;
  padding: 1rem 1.5rem;
  cursor: pointer;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid #dedcd6;
  border-top: 0;
}
.react-selectize .dropdown-menu .club-option:first-child {
  border-top: 1px solid #dedcd6;
}
.react-selectize .dropdown-menu .club-option:last-child {
  border-bottom: 1px solid #dedcd6;
}

.react-selectize .react-selectize-search-field-and-selected-values {
  display: -ms-flexbox;
  display: flex;
  min-height: 34px;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 5px;
}
.react-selectize .react-selectize-search-field-and-selected-values .club-value {
  line-height: 34px;
}
.react-selectize
  .react-selectize-search-field-and-selected-values
  .resizable-input {
  background: none;
  border: none;
  outline: none;
  font-size: 1em;
  margin: 2px;
  padding: 4px 0;
  vertical-align: middle;
  width: auto !important;
  position: relative;
  z-index: 100;
}

.react-selectize .react-selectize-toggle-button-container {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 34px;
  width: 32px;
}
