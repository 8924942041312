$mobile-sidebar-block: mobile-sidebar;

.#{$mobile-sidebar-block} {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms ease-out;

  &--is-open {
    opacity: 1;
    pointer-events: initial;

    // TODO: This is an example of when BEM gets awkward in SASS (having to
    // declare the block and then set overrides is kinda gross). I'm not sure
    // exactly how to make this better, but there must be something we can do.
    .#{$mobile-sidebar-block}__menu {
      right: 0;
      transform: translateX(0%);
    }

    .#{$mobile-sidebar-block}__overlay {
      opacity: 1;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    opacity: 0;
    transition: opacity 300ms ease-out;
  }

  &__menu {
    position: absolute;
    right: -100%;
    top: 0;
    height: 100vh;
    background: white;
    width: 60%;
    max-width: 250px;
    padding: 8px 0;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.15);
    transform: translateX(100%);
    transition: transform 300ms ease-out;
  }

  &__item {
    display: block;
    padding: 16px;
    color: #333;
    font-size: 20px;
    text-align: left;
    text-decoration: none;
    cursor: pointer;

    &--small-text {
      font-size: 16px;
    }

    &:hover,
    &:active,
    &--is-active {
      font-weight: bold;
      text-decoration: none;
    }
  }

  &__divider {
    height: 1px;
    background: rgba(0, 0, 0, 0.15);
    margin: 8px 0;
  }

  &__user-icon {
    display: inline-block;
    margin-right: 8px;
  }

  &__back-arrow {
    margin-right: 10px;
  }
}
