.tag {
  padding: 1px 5px;
  font-size: 1rem;
  background-color: $dorian-gray;
  color: #fff;

  &.tag-primary,
  &.tag-new {
    background-color: $color-primary;
    color: #fff;
  }

  &.tag-secondary,
  &.tag-active {
    background-color: $color-secondary;
    color: #fff;
  }

  &.tag-tertiary,
  &.tag-premium {
    background-color: $color-primary;
    color: #fff;
  }

  &.tag-success {
    background-color: #5cb85c;
  }

  &.tag-info {
    background-color: #5bc0de;
  }

  &.tag-warning {
    background-color: #f0ad4e;
  }

  &.tag-danger {
    background-color: #d9534f;
  }

  &.tag-md {
    padding: 2px 10px;
    font-size: 1.25rem;
  }
}

.tag-group {
  overflow: hidden;

  .tag {
    float: left;

    &:not(:last-of-type) {
      margin-right: 3px;
    }
  }
}
