// TODO: Split this file up and organize things a bit better.

//------------------
// Fonts
//------------------

@import url("https://use.typekit.net/nnl5hxd.css");

$default-font-family: "trade-gothic-next", sans-serif;
$fontawesome-font-family: "Font Awesome 5 Pro";
$fontawesome-brands-font-family: "Font Awesome 5 Brands";

//------------------
// Colors
//------------------

$vivid-orange: #f68b1f;
$deep-water: #0f2b5b;
$slate: #335687;
$dorian-gray: #9b9993;
$moss: #74b04a;
$dark-gray: #333;
$dark-grayer: #4a4a4a;
$warning-red: rgb(183, 71, 48);
$light-gray: #f4f3f4;

$color-primary: #006fba;
$color-secondary: #74b04a;
$color-tertiary: $slate;
$color-quaternary: $deep-water;

//------------------
// Transitions
//------------------

$transition-default: all 0.1s ease-in-out;

//------------------
// Image Asset Path
//------------------

$assetPath: "/team_edition/images";

//------------------
// Colors (from C3PO)
//------------------

$black: rgb(51, 51, 51);
$dorian_grayer: lighten($black, 60%);
$dorian_lighter: lighten($dorian_gray, 28%);
$button_action_color: rgb(116, 176, 74); // Green
$border_color: rgb(222, 220, 214); // Light gray

//------------------
// Responsive (from C3PO)
//------------------

$breakpoints: (
  "mobile": 320px,
  "tablet": 700px,
  "screen": 900px,
  "desktop": 1200px,
);

@function breakpoint($key) {
  @if map_has_key($breakpoints, $key) {
    @return map_get($breakpoints, $key);
  }
}

@mixin respond-to($media) {
  @media (min-width: breakpoint($media)) {
    @content;
  }
}
