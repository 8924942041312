.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  overflow: scroll;
  outline: 0;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.25);
  transition: all 0.25s ease-in-out;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &.modal-spinner {
    display: flex;
    align-items: center;

    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 50px;
        }
      }
    }
  }

  .braintree_modal {
    padding: 10px 5px 0px 0px;
    overflow: auto;
    width: 100%;
  }

  .braintree-submit {
    text-align: right;
    padding-bottom: 16px;
  }

  @media (min-width: 630px) {
    .braintree-sheet__content--form .braintree-form__flexible-fields {
      flex-wrap: nowrap;
    }
  }

  .braintree-form__field-group {
    label {
      display: block;
      .braintree-form__field {
        width: 100%;
      }
    }
  }

  .modal-dialog {
    position: relative;
    margin: 10px;
    width: auto;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
      width: 600px;
      margin: 30px auto;
    }

    @media (min-width: 991px) {
      width: 900px;
    }

    .modal-content {
      position: relative;
      background-color: #fff;

      .modal-header {
        padding: 15px 30px;
        background-color: $color-primary;
        color: #fff;

        .close {
          float: right;
          margin: 0;
          margin-top: 15px;
          font-size: 2.5rem;
          opacity: 0.9;

          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }
      }

      .modal-body {
        padding: 30px;
        overflow: auto;
        width: 100%;

        &.modal-body--signup {
          overflow: initial;
        }

        &.modal-body--manage,
        &.modal-body--edit {
          padding-bottom: 80px;
        }
      }

      .modal-footer {
        padding: 0 30px 30px;
      }
    }
  }

  @media (min-width: 768px) {
    .modal-dialog {
      width: 600px;
      margin: 30px auto;
    }
  }
}

.modal {
  .modal-body--manage {
    .form-buttons {
      text-align: right;
      margin-top: 50px;

      @include respond-to(screen) {
        position: absolute;
        bottom: 30px;
        right: 30px;
        margin-top: 0;
      }

      .btn,
      .btn-modal-container {
        width: 100%;
        margin-bottom: 5px;

        @include respond-to(screen) {
          width: auto;
          margin-left: 5px;
          margin-bottom: 0;
        }
      }

      .modal {
        .btn {
          width: auto;
        }
      }
    }

    .additional-info {
      margin-bottom: -50px;

      .form-group {
        margin-top: -150px;

        @include respond-to(screen) {
          margin-top: 0;
        }
      }

      .alert {
        display: none;

        @include respond-to(screen) {
          display: block;
        }
      }

      .btn,
      .btn-modal-container {
        width: 100%;
        margin-bottom: 5px;

        @include respond-to(screen) {
          width: auto;
          margin-bottom: 0;
          margin-right: 5px;
        }
      }
    }
  }
}

.modal {
  .modal-body--edit {
    .form-buttons {
      position: static;

      .btn[type="submit"] {
        position: static;
        width: 100%;
        margin-bottom: 5px;

        @include respond-to(screen) {
          position: absolute;
          width: auto;
          bottom: 30px;
          right: 30px;
          margin-bottom: 0;
        }
      }
    }

    .save-and-invite {
      margin-bottom: 5px;
      margin-top: 65px;
      width: 100%;

      @include respond-to(screen) {
        position: absolute;
        bottom: 30px;
        right: 115px;
        margin-top: 0;
        margin-bottom: 0;
        width: auto;
      }
    }

    .additional-info {
      margin-bottom: -50px;

      .alert {
        display: none;

        @include respond-to(screen) {
          display: block;
        }
      }

      .btn,
      .btn-modal-container {
        margin-bottom: 5px;
        width: 100%;

        @include respond-to(screen) {
          width: auto;
          margin-bottom: 0;
          margin-right: 5px;
        }
      }

      .form-group {
        margin-top: -150px;

        @include respond-to(screen) {
          margin-top: 0;
        }
      }
    }
  }
}

.modal {
  .modal-body--signup {
    .club-search-form {
      .react-selectize {
        .dropdown-menu {
          position: static;
          max-height: 412px;
          overflow: auto;
        }
      }

      .search-holder {
        position: relative;
        margin-bottom: 30px;
      }

      .create-btn {
        position: absolute;
        bottom: 30px;
        z-index: 2;
      }
    }
  }
}

.modal {
  .modal-body--share {
    .share-group {
      margin: 1rem 0;
      padding: 2rem;
      border: 1px solid $dorian-lighter;
    }

    .share-link-actions {
      display: flex;

      .copy-to-clipboard {
        flex: 1;
      }

      .share-go-link {
        margin-left: 0.5rem;
      }
    }

    .share-buttons {
      display: flex;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 32px;
        height: 32px;
        margin: 0 3px;

        .fa {
          margin: 0;
        }
      }
    }
  }
}
