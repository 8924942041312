.relationships {
  display: flex;

  .panel {
    text-align: center;
    width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px;
    color: #fff;
  }

  .athlete-panel {
    background-image: url(#{$assetPath}/backgrounds/diamond.png);

    .rating {
      font-size: 2rem;
      margin-bottom: 0.5rem;
      vertical-align: text-top;

      .fa {
        color: $vivid-orange;
      }
    }
  }

  .college-panel {
    background-image: url(#{$assetPath}/backgrounds/diamond-gray.png);
  }

  .divider {
    position: relative;
    width: 1px;
    display: flex;
    align-items: center;

    .match {
      position: absolute;
      left: -50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #fff;

      .score {
        font-size: 3rem;
      }

      .subtitle {
        font-size: 1rem;
        text-transform: uppercase;
        color: $dorian-gray;
      }
    }
  }
}
