.btn,
button,
input[type="button"],
input[type="submit"] {
  border-radius: 5px;
  padding: 10px 25px;
  border: 1px solid transparent;
  transition: all 0.1s ease-in-out;
  background-color: $dorian-gray;
  color: #fff;
  display: inline-block;
  text-decoration: none;

  .fa {
    margin-right: 10px;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: lighten($dorian-gray, 10);
    text-decoration: none;
    color: #fff;
  }

  &:focus {
    outline: 0;
  }

  &.btn-toggle {
    &.selected {
      background-color: $color-tertiary;
    }
  }

  &.btn-primary {
    background-color: $color-primary;
    color: #fff;

    &:hover {
      background-color: lighten($color-primary, 10);
    }

    &.btn-outlined {
      &:hover {
        background-color: lighten($color-primary, 10);
        color: #fff;
      }

      border-color: $color-primary;
      color: $color-primary;
    }
  }

  &.btn-secondary {
    background-color: $color-secondary;
    color: #fff;

    &:hover {
      background-color: lighten($color-secondary, 10);
    }

    &.btn-outlined {
      &:hover {
        background-color: lighten($color-secondary, 10);
        color: #fff;
      }

      border-color: $color-secondary;
      color: $color-secondary;
    }
  }

  &.btn-tertiary {
    background-color: $color-tertiary;
    color: #fff;

    &:hover {
      background-color: lighten($color-tertiary, 10);
    }

    &.btn-outlined {
      &:hover {
        background-color: lighten($color-tertiary, 10);
        color: #fff;
      }

      border-color: $color-tertiary;
      color: $color-tertiary;
    }
  }

  &.btn-quaternary {
    background-color: $color-quaternary;
    color: #fff;

    &:hover {
      background-color: lighten($color-quaternary, 10);
    }

    &.btn-outlined {
      &:hover {
        background-color: lighten($color-quaternary, 10);
        color: #fff;
      }

      border-color: $color-quaternary;
      color: $color-quaternary;
    }
  }

  &.btn-warning {
    background-color: $warning-red;

    &:hover,
    &:focus {
      background: darken($warning-red, 10%);
      color: #fff;
    }
  }

  &.btn-outlined {
    border-color: #fff;
    border-style: solid;
    border-width: 1px;
    color: $dark-gray;
    padding: 9px 24px;
    background-color: transparent;

    &:hover {
      background-color: lighten($dorian-gray, 10);
      color: #fff;
    }
  }

  &.btn-inversed {
    background: #fff;
    border: 1px solid #fff;
    color: #000;

    &:hover,
    &:focus {
      background: #fff;
    }
  }

  &.btn-outlined--white {
    background: none;
    border: 1px solid #fff;
    color: #fff;

    &:hover,
    &:focus {
      background: none;
    }
  }

  &.btn-xs {
    padding: 5px;
    font-size: 1.25rem;
  }

  &.btn-sm {
    padding: 5px 15px;
    font-size: 1.25rem;
  }

  &.btn-md {
    padding: 5px 25px;
  }

  &.btn-rounded {
    border-radius: 3rem;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .spinner,
  .spinner:after {
    width: 14px;
    height: 14px;
  }

  .spinner {
    margin-right: 10px;
    display: inline-block;
    border-style: solid;
    border-width: 2px;
    border-top-color: rgba(255, 255, 255, 0.2);
    border-right-color: rgba(255, 255, 255, 0.2);
    border-bottom-color: rgba(255, 255, 255, 0.2);
    border-left-color: #fff;
    margin-bottom: -2px;
  }
}

.btn-group {
  overflow: hidden;
  display: inline-block;

  .btn {
    float: left;
    border-radius: 0;

    &:not(:last-child) {
      margin-right: 1px;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.btn-margin-group {
  margin: 0 -4px;

  .btn {
    margin: 4px;
  }
}

button[type="submit"] {
  position: relative;

  .spinner {
    position: absolute;
    top: 12px;
    left: 5px;
  }
}

.ml-5 {
  margin-left: 5px;
}

.btn-modal-container {
  display: inline-block;
  text-align: left;
}
