// Find Colleges
.search {
  width: 100%;
  margin-right: -95px;
  padding-right: 100px;

  .form-control {
    width: 100%;
  }
}

.college-activities-header {
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px 0;
}

// Filters
.college-filters {
  margin-bottom: 3em;

  .filter-results {
    text-align: center;

    @include respond-to(tablet) {
      text-align: right;
    }
  }
}

.college-search {
  border-bottom: 1px solid $dorian-gray;
  padding-right: 35px;
  overflow: hidden;
  position: relative;

  table.table {
    border-bottom: none;
    margin-bottom: 0;
    width: 300px;
    float: right;
    padding: 5px 0;

    thead > tr > th {
      border-bottom: none;
      text-align: center;
    }
  }

  // TODO: Get rid of this, and never do it again! This is a big hack.
  #name {
    display: none;

    @media (min-width: 768px) {
      position: absolute;
      display: table-cell;
      left: 10px;
    }
  }

  // TODO: WHAT DID I JUST SAY?! STOP DOING THIS! But I'm doing it now because... time is short.
  // Also, what makes the above worse is the absolute styling for no discernible reason.
  #lastActivityDate {
    display: none;

    @media (min-width: 768px) {
      display: table-cell;
    }
  }
}

// Toggled Open Filters
.filters-open {
  border-bottom: 1px solid $border_color;
  padding-bottom: 3em;
  margin: -1em 0 3em;

  .col-xs-6 {
    @media screen and (max-width: 800px),
      screen and (min-width: 992px) and (max-width: 1202px) {
      width: 100%;
      float: none;
      margin-bottom: 2em;
    }
  }

  .row {
    width: 100%;
    display: block;
    margin: 1.5em 0 1.5em -15px;

    @include respond-to(tablet) {
      margin-left: -15px; // defaults for TED
      margin-right: -15px;
    }
  }

  .btn-group {
    .btn {
      background: $color-primary;
      padding: 0.5em 0.85em;

      @include respond-to(tablet) {
        padding: 0.5em 1em;
      }

      &:not(:last-child) {
        margin-right: 0;
      }

      &.btn-selected {
        background: $color-secondary;
        color: #fff;
        border-top: 1px solid $color-secondary;
        border-bottom: 1px solid $color-secondary;
        border-right: 1px solid transparent;
        border-left: 1px solid #fff;
        transition: $transition-default;

        &:first-child {
          border-left: 1px solid transparent;
        }

        &:last-child {
          border-right: 1px solid transparent;
        }
      }

      &.btn-selected + .btn {
        border-left: 1px solid #fff;
      }

      &.btn-outlined {
        color: #fff;

        &:hover {
          background: lighten($color-primary, 10);
          border-top: 1px solid #fff;
          border-bottom: 1px solid #fff;
          border-right: 1px solid transparent;
          border-left: 1px solid transparent;
          transition: $transition-default;
        }
      }
    }
  }

  .rc-slider-track {
    background-color: $color-secondary;
  }

  .rc-slider-dot-active {
    border-color: $color-secondary;
  }

  .rc-slider-handle {
    border-color: $color-secondary;
  }

  .apply-filters {
    margin-top: 3em;
    margin-left: 0;
    text-align: center;

    @include respond-to(tablet) {
      text-align: right;
    }

    .btn {
      margin-left: 0.5em;
    }
  }

  .tree-selector-box {
    user-select: none;
    border: 1px solid $border_color;
    border-radius: 3px;
    padding: 5px;
  }

  .tree-selector {
    h4 {
      cursor: pointer;
    }
  }

  .disclosure-icon {
    cursor: pointer;
    float: left;
  }

  .tree-node {
    .selectable-label {
      cursor: pointer;
      display: block;
      padding-left: 5px;

      &.selected-label {
        background: $color-secondary;
        color: #fff;
      }
    }

    ul {
      padding-left: 1.3em;
      margin-bottom: 0px;
    }

    li {
      list-style: none;
    }
  }
}

.m-control-screen {
  .college-filters {
    margin: 0;

    h4 {
      color: #fff;
    }

    .filters-open {
      margin: 0;
      padding: 0;
      border: 0;

      .row {
        margin: 0;
      }
    }

    .apply-filters {
      margin: 0;

      .btn-outlined {
        border: 1px solid #fff;
        color: #fff;

        &:hover {
          background-color: transparent;
        }

        &.is-applied {
          background: #fff;
          border: 1px solid #fff;
          color: #000;

          &:hover,
          &:focus {
            background: #fff;
          }
        }
      }
    }

    .btn-group {
      .btn {
        padding: 0.5em;
      }

      .btn-outlined {
        color: #fff;
      }
    }

    .rc-slider {
      .rc-slider-mark-text-active {
        color: #fff;
      }
    }

    .tree-selector-box {
      color: #fff;
    }
  }
}
